const SCREENWIDTH = window.innerWidth;

const supervisors = new Swiper('.supervisors__slider', {
    slidesPerView: 1.7,
    centeredSlides: false,
    loop: true,
    slideToClickedSlide: true,
    spaceBetween: 48,
    pagination: {
        el: '.supervisors__pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
        },
    },
    navigation: {
        nextEl: ".supervisors__next",
        prevEl: ".supervisors__prev"
    },
    breakpoints: {
        '0': {
            slidesPerView: 1,
            spaceBetween: 30
        },
        '667': {
            slidesPerView: 1.7,
            spaceBetween: 30
        }
    }
});


function checkCounters() {
    const duration = 3000;

    function animateCounter($counter) {
        const target = parseInt($counter.attr('data-target'), 10);
        const start = 0;
        let startTime = null;

        const updateCount = function (timestamp) {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;
            const currentValue = Math.min(Math.floor((progress / duration) * target), target);

            $counter.text(currentValue.toLocaleString('en').replace(/,/g, ' '));

            if (progress < duration) {
                requestAnimationFrame(updateCount);
            } else {
                $counter.text(target.toLocaleString('en').replace(/,/g, ' '));
            }
        };

        requestAnimationFrame(updateCount);
    }

    function isElementInView(element) {
        const elementTop = $(element).offset().top;
        const elementBottom = elementTop + $(element).outerHeight();
        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    $('.counter').each(function () {
        const $counter = $(this);
        if (isElementInView($counter) && !$counter.hasClass('animated')) {
            $counter.addClass('animated');
            animateCounter($counter);
        }
    });
}


supervisors.on('init', updateBulletsWidth);
supervisors.on('slideChange', updateBulletsWidth);
function updateBulletsWidth() {
    let bullets = document.querySelectorAll('.swiper-pagination-bullet');
    let pagination = document.querySelector('.supervisors__pagination');
    if (!bullets.length || !pagination) return;

    let totalSlides = supervisors.slides.length - supervisors.loopedSlides * 2;
    let maxPaginationWidth =   (SCREENWIDTH > 666) ? 400 : 130;


    let bulletWidth = (maxPaginationWidth / totalSlides) - 5;
    bullets.forEach(bullet => {
        bullet.style.width = bulletWidth + 'px';
    });
}


function changeInMob(){
    if(SCREENWIDTH <= 666){
        $('.client__info').prepend($('.client__info-banner'))
        $('.main__btn').after($('.gallery__item:nth-child(2)'))
        $('.supervisors__prev').after($('.supervisors__pagination'))
        $('.supervisors__slider').after($('.supervisors__nav'))
    }
}

$(document).ready(function(){
    updateBulletsWidth()
    changeInMob()
    $(window).on('load scroll', checkCounters);
});

